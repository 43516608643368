<template>
  <div class="about">
    <mu-appbar color="#555">
      <mu-button icon slot="left" @click="showMenuList">
        <mu-icon value="menu"></mu-icon>
      </mu-button>
      <div style="font-weight: 600; font-size: 20px;">管理後台</div>
    </mu-appbar>
    <!-- 選單 -->
    <!-- LOGO -->
    <div style="position: fixed; bottom: 10px; left: 0px; text-align: left; z-index: 50000;">
      <img src="https://i.imgur.com/SITKieL.png" width="120"/>
      <div style="padding-left: 12px; font-size: 13px;">Copyright © 2020 埃立思科技股份有限公司 版權所有</div>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      index: '1',
      dialogOpen: false,
      dialogContent: '',
      password: '',
      wrongPass: false,
      passwordDia: true,
      changeLog: false,
      showMenu: false,
      isCollapse: true,
      vactiveStep: 0
    }
  },
  mounted() {
    if (localStorage.getItem('nowPage')) {
      this.index = localStorage.getItem('nowPage');
    }
  },
  methods: {
    getIndex(idx) {
      this.index = idx;
      localStorage.setItem('nowPage', idx);
    },
    showMenuList() {
      this.showMenu = !this.showMenu;
    },
    col(val) {
      if (val === 'menu') {
        if (this.showMenu) {
          return 6;
        }
        else {
          return 0;
        }
      }
      else {
        if (this.showMenu) {
          return 18;
        }
        else {
          return 24;
        }
      }
    },
    checkPass() {
      if (this.password === 'ruentex') {
        this.wrongPass   = false;
        this.passwordDia = false;
      }
      else {
        this.wrongPass = true;
      }
    }
  }
}

</script>

<style scoped>
  .about {
    margin: 0 auto;
  }
  .login_btn {
    padding: 5px 5px 0 5px;
    background-color: #00c300;
    display: inline-block;
    border-radius: 5px;
    width: 240px;
    margin-top: 10px;
    cursor: pointer;
  }
  .logo_text {
    color: #fff;
    font-weight: 600;
    line-height: 52px;
    font-size: 20px;
    display: inline-block;
  }
</style>
